@use '../../../../styles/shared/include' as *;

.AgencyHeroImageFormSection {
	.AgencyHeroimageColTextForm {
		@media #{$lgn} {
			display: flex;
			flex-direction: row-reverse;
		}
		min-height: calc(100vh - (211px + var(--announcement-banner-height)));
		.textSection {
			padding: rem(40) rem(20);
			color: $deepBlue;
			@media #{$lgn} {
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: calc((840 / 1440) * 100%);
				padding: rem(112) rem(120) rem(74) rem(120);
			}
			width: 100%;
			h1 {
				font-size: rem(40);
				font-weight: 300;
				line-height: 100%;
				margin-bottom: rem(24);
				color: $deepBlue;
				@media #{$lgn} {
					font-size: rem(64);
				}
			}

			p {
				font-size: rem(18);
				font-weight: 500;
				line-height: 140%;
				padding-bottom: rem(46); //46
				color: $deepBlue;
			}

			fieldset {
				label {
					margin-bottom: rem(5);
					font-weight: 500;
					line-height: 125%;
					color: $black;
				}
				select {
					width: 100%;
					margin-bottom: rem(25);
					margin-top: rem(5); //6
					border: rem(1) solid $periwinkleGrey;
					border-radius: rem(3);
					font-size: rem(16);
					font-weight: 500;
					padding: rem(12) rem(24);
					line-height: 125%;
					height: rem(48);
					-webkit-appearance: none;
					background: $dropdown no-repeat calc(100% - rem(24));
					font-family: $primary;
				}
				input {
					width: 100%;
					padding: rem(12);
					border: rem(1) solid $periwinkleGrey;
					border-radius: rem(3);
					font-size: rem(16);
					font-weight: 500;

					webkit-appearance: textfield;
					margin-top: rem(3);
					padding: rem(12) rem(20);
				}
				span {
					display: block;
					height: rem(23);
					margin-bottom: rem(8);
					color: red;
				}
				.formHeading {
					color: $deepBlue;
					font-size: rem(20);
					font-style: normal;
					font-weight: 500;
					line-height: rem(20);
					margin-bottom: rem(16);
				}
			}
			.formTagline {
				padding-bottom: rem(16);
				font-size: rem(20);
				font-weight: 500;
				line-height: 100%;
				display: block;
			}
		}
		.imageSection {
			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}

			@media #{$lgn} {
				height: auto;
				width: calc((600 / 1440) * 100%);
			}
		}

		&--imageSmallDesign {
			@media #{$lgn} {
				.textSection {
					width: 58.33%;
				}
				.imageSection {
					width: 41.66%;
				}
			}
		}

		&--imageWithFormHeading {
			.textSection {
				margin-bottom: rem(42);
				h3 {
					@media #{$md} {
						font-size: rem(64);
					}
					@media #{$xl} {
						min-width: rem(601);
					}
				}
			}
			@media #{$lgn} {
				.textSection {
					width: 58.33%;
				}
				.imageSection {
					width: 41.66%;
				}
			}
		}
	}
}
