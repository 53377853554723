@use '../../../styles/shared/include' as *;

.textInput {
    background: #ffffff;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: $primary;
    font-weight: 500;

    label {
        color: var(--Light-UI-Text-Secondary, #4F5E78);
        font-family: $primary;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    input {
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid var(--Light-UI-Border-Secondary, #8291AC);
        font-family: $primary;
        font-weight: 500;
        font-size: 16px;
    }
    
    input:user-invalid + div.errorIcon {
      display: block;
    }

    input:user-invalid {
      border-radius: 4px;
      border: 1px solid var(--Light-UI-Border-Error, #B30015);
      background: var(--Light-UI-Surface-Error, #FFADB7) url('./Warning.svg') no-repeat;
      background-position: right 12px center;
    }

    input:hover {
      border-radius: 4px;
      border: 1px solid var(--Light-UI-Border-Primary, #003049);
    }
    
    input:focus {
      outline: none !important;
      border-radius: 4px;
      border: 1px solid var(--Light-UI-Border-Primary, #003049);
    }

    input:disabled {
      border-radius: 4px;
      border: 1px solid var(--Light-UI-Border-Tertiary, #BFC6D4);
    }
}

.checkboxInput {
  input[type="checkbox"] {
    outline: none !important;
    border: none !important;
    position: absolute; // this will sit directly behind the control the user actually sees
    z-index: -1;
    height: 28 px;
    width: 28 px;
    pointer-events: none;
  }

  input[type="checkbox"]:active + label > span, 
  input[type="checkbox"]:focus + label > span {
    border: 1px solid var(--Light-UI-Border-Primary, #003049);
  }
  input[type="checkbox"]:disabled + label > span {
    border: 1px solid var(--Light-UI-Border-Tertiary, #BFC6D4);
  }
  
  label {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .checkmark {
      display: flex;
      width: 24px;
      height: 24px;
      padding: 12px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 4px;
      border: 1px solid var(--Light-UI-Border-Secondary, #8291AC);
      background: var(--Light-UI-Surface-Contrast, #003049);
      color: var(--Light-UI-Text-Inverse, #FFF);

      font-family: "DM Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      
      .icon {
        font-size: 24px;
        flex-shrink: 0;
        fill: var(--Dark-UI-Icon-Primary, #FFF);
      }
    }

    .checkempty {
      display: flex;
      width: 24px;
      height: 24px;
      padding: 12px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 4px;
      border: 1px solid var(--Light-UI-Border-Secondary, #8291AC);
      background: var(--Light-UI-Surface-Primary, #FFF);

      .icon {
        display: hidden;
      }
    }
  }
}
