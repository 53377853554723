@use '../../../styles/shared/include' as *;

// https://moderncss.dev/pure-css-custom-styled-radio-buttons/
.radioInputList {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  font-family: $primary;

  label {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    font-weight: 500;
  }
  
  input[type="radio"] {
    outline: none !important;
    border: none !important;
    position: absolute; // this will sit directly behind the control the user actually sees
    z-index: -1;
    height: 26px;
    height: 26px;
    pointer-events: none;
  }

  input[type="radio"]:user-invalid + label > span {
    border-radius: 100px;
    border: 1px solid var(--Light-UI-Border-Error, #B30015);
    background: var(--Light-UI-Surface-Error, #FFADB7);
  }

  input[type="radio"]:active + label > span, 
  input[type="radio"]:focus + label > span {
    border: 1px solid var(--Light-UI-Border-Primary, #003049);
  }
  
  input[type="radio"]:disabled + label > span {
    border: 1px solid var(--Light-UI-Border-Tertiary, #BFC6D4);
  }
}

.radioInputListItem {
  label {
    cursor: pointer;
    flex-direction: row;

    .icon {
      color: var(--Light-UI-Border-Inverse, #FFF);
      font-size: 24px;
      flex-shrink: 0;
    }

    .checkmark, .checkempty {
      display: flex;
      width: 24px;
      height: 24px;
      padding: 12px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      border: 1px solid var(--Light-UI-Border-Secondary, #8291AC);
    }

    .checkmark {
      gap: 2px;
      background: var(--Light-UI-Surface-Contrast, #003049);
    }

    .checkempty {
      gap: 4px;
      background: var(--Light-UI-Surface-Primary, #FFF);

      .icon {
        display: none;
      }
    }
  }
}
