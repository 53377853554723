@use '../../../../styles/shared/include' as *;

.onlyTextContainer {
	text-align: center;
	color: $deepBlue;
	background-color: $brightYellow;
	padding: rem(24) rem(0);
	p {
		font-size: rem(20);
		font-weight: 500;
		line-height: 140%;
		&:empty {
			display: none;
		}
	}
	@media #{$md} {
		padding: rem(43) rem(0);

		p {
			font-size: rem(40);
		}
	}
	:global {
		.slick-track {
			display: flex !important;
			align-items: center;
		}
	}
}
