@use '../../../styles/shared/include' as *;

.fileInput.filled label {
  border: 1px solid var(--Light-UI-Border-Confirmation, #2A6100);
  background: var(--Light-UI-Surface-Confirmation, #D1FFAD);
  color: var(--Light-UI-Icon-Secondary, #4F5E78);
}

.fileInput.notFilled label {
  border: 1px dashed var(--Light-UI-Border-Secondary, #8291AC);
  background: var(--Light-UI-Surface-Primary, #FFF);
  color: var(--Light-UI-Icon-Secondary, #4F5E78);
}

.fileInput.dragging label {
  border: 1px dashed var(--Light-UI-Border-Secondary, #8291AC);
  background: var(--Light-UI-Surface-Info, #ADE3FF);
  color: var(--Light-UI-Icon-Secondary, #4F5E78);
}

.fileInput {
  display: flex;
  flex-direction: column;
  height: 75px;
  // padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
	font-family: $primary;
  font-weight: 500;

  input[type="file"] {
    position: absolute; // this will sit directly behind the control the user actually sees
    z-index: -1;
    height: 32px;
    width: 64px;
    pointer-events: none;
  }

  input[type="file"]:user-invalid + label{
    border-radius: 4px;
    border: 1px dashed var(--Light-UI-Border-Error, #B30015);
    color: black;
    background: var(--Light-UI-Surface-Error, #FFADB7);
  }

  input[type="file"]:active + label,
  input[type="file"]:focus + label {
    border: 1px dashed var(--Light-UI-Border-Primary, #003049);
    border-radius: 4px;
  }

  label {
      cursor: pointer;
      color: var(--Light-UI-Text-Primary, #003049);
      border-radius: 4px;
      align-self: stretch;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 12px;
      font-weight: 500;

      .icon {
        width: 32px;
        height: 40px;
        font-size: 40px;
      }
  }
}
