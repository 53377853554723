@use '../../styles/shared/include' as *;

.search__item {
	position: relative;
	:global {
		.title__name {
			display: flex;
			align-items: flex-start;
			padding-bottom: rem(12);
			margin-bottom: rem(18);
			border-bottom: rem(1) solid $periwinkleGrey;
			@include transition();
			@media #{$md} {
				padding-bottom: rem(17);
				margin-bottom: rem(23);
				border-bottom: rem(2) solid $periwinkleGrey;
			}
			h2 {
				width: calc(100% - 24px);
				color: $deepBlue;
				font-size: rem(20);
				line-height: 120%;
				font-weight: 700;
				padding-top: rem(2);
				@media #{$md} {
					font-size: rem(24);
					letter-spacing: rem(0.4);
				}
			}
			svg {
				width: rem(24);
				position: relative;
				top: rem(4);
				path {
					stroke: $deepBlue;
					@include transition();
				}
			}
			&:hover {
				border-color: $periwinkleGrey;
				svg {
					path {
						stroke: $periwinkleGrey;
					}
				}
			}
		}
		ul {
			display: block;
			li {
				margin-bottom: rem(16);
				p {
					font-size: rem(16);
					line-height: 140%;
					color: $deepBlue;
					font-weight: 400;
					margin-bottom: rem(8);
					@media #{$md} {
						font-size: rem(18);
					}
				}
				a {
					font-size: rem(16);
					line-height: 140%;
					color: $deepBlue;
					font-weight: 600;
					@media #{$md} {
						font-size: rem(18);
					}

					&:hover {
						color: $hoverBlue;
					}
				}
			}
		}
		.search__item-buttom {
			a {
				padding: rem(11) rem(20);
				font-size: rem(16);
				line-height: 111.11%;
				margin-top: rem(8);
				@media #{$md} {
					font-size: rem(18);
				}
			}
		}
	}
}
