@use '../../include' as *;

.button {
	background-color: var(--bw-background);
	border-width: 1px;
	text-wrap: nowrap;

	&.link {
		border-color: transparent;

		&:hover {
			background-color: var(--bw-background);
			color: var(--bw-foreground);
		}
	}

	&:hover {
		background-color: var(--bw-foreground);
		color: var(--bw-background);
		border-color: var(--bw-background);
	}

	&.default {
		--bw-background: inherit;
		--bw-foreground: inherit;
	}

	display: inline-block;
	font-size: rem(20);
	font-family: 'DM Sans', sans-serif;
	padding: rem(16);
	font-weight: 500;
	line-height: rem(20);
	border-radius: rem(100);
	cursor: pointer;
	text-align: center;
	transition: background-color 0.3s, color 0.3s;

	svg {
		display: inline-block;
		margin-left: rem(8);
	}

	:global {
		.svg {
			&.is-icon-right {
				margin-left: 0;
				margin-right: rem(8);
			}
		}
	}

	&:hover {
		path {
			fill: var(--bw-foreground) !important;
		}
	}

	&--isMenu {
		align-content: center;
		font-size: 1rem;

		@media (orientation: landscape) and (max-height: 420px) {
			margin-right: 0 !important;
		}
	}

	&.narrow {
		padding: rem(8) rem(16);
	}
}
