@use '../../../../styles/shared/include' as *;

.insuranceImageColTextForm {
	display: flex;
	flex-direction: column-reverse;
	min-height: calc(100vh - (211px + var(--announcement-banner-height)));
	@media #{$lgn} {
		flex-direction: row;
	}
	.textSection {
		align-self: center;

		padding: rem(48) rem(20);
		color: $deepBlue;
		@media #{$lgn} {
			width: calc((840 / 1440) * 100%);
			padding: rem(60) rem(120) rem(60) rem(118);
		}
		width: 100%;
		flex-direction: column;
		h1 {
			font-size: rem(40);
			font-weight: 300;
			line-height: 100%;
			margin-bottom: rem(24);
			@media #{$lgn} {
				font-size: rem(64);
			}
		}
		span {
			display: block;
		}

		.description {
			padding-bottom: rem(48);
		}

		fieldset {
			display: flex;
			flex-direction: column;
			label {
				margin-bottom: rem(4);
				font-weight: 500;
				line-height: 125%;
				color: $black;
			}
			select {
				width: 100%;
				margin-bottom: rem(24);
				border: rem(1) solid $periwinkleGrey;
				border-radius: rem(3);
				font-size: rem(16);
				font-weight: 500;
				padding: rem(12) rem(24);
				line-height: 125%;
				-webkit-appearance: none;
				background: $dropdown no-repeat calc(100% - rem(24));
				font-family: $primary;
			}
			input {
				width: 100%;
				border: rem(1) solid $periwinkleGrey;
				border-radius: rem(3);
				font-size: rem(16);
				font-weight: 500;
				padding: rem(12) rem(20);
			}
			span {
				display: block;
				height: rem(25);
				margin-bottom: rem(8);
				color: red;
			}

			.formHeading {
				color: $deepBlue;
				font-size: rem(20);
				font-style: normal;
				font-weight: 500;
				line-height: rem(20);
				margin-bottom: rem(16);
			}
		}
		.formTagline {
			padding-bottom: rem(16);
			font-size: rem(20);
			font-weight: 500;
			line-height: 100%;
			display: block;
		}
	}
	.imageSection {
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}

		@media #{$lgn} {
			height: auto;
			width: calc((600 / 1440) * 100%);
		}
	}
	&--imageSmallDesign {
		@media #{$lgn} {
			.textSection {
				width: 58.33%;
				padding: rem(112) rem(120);
			}
			.imageSection {
				width: 41.66%;
			}
		}
	}
}
