@use '../../../../styles/shared/include' as *;

.insuranceBoxDescriptionWithImageRolloverSection {
	background-color: $cream;

	.insuranceBoxDescriptionWithImageRollover {
		.headWrapper {
			display: flex;
			flex-direction: column-reverse;
			height: fit-content;
			@media #{$md-1} {
				flex-direction: row;
			}
		}

		.imageContainer {
			width: 100%;
			height: auto;

			img {
				width: 100%;
				height: rem(260);
				@media #{$sml} {
					height: rem(600);
				}
			}
			@media #{$nm} {
				img {
					min-height: rem(350);
				}
			}

			@media #{$md-1} {
				width: 42%;
				img {
					object-fit: cover;
					height: 100%;
					aspect-ratio: (600/487);
				}
			}
		}

		.textContainer {
			color: $deepBlue;
			padding: rem(48) rem(20);
			h2 {
				font-size: rem(40);
				line-height: 100%;
				font-weight: 300;
				margin-bottom: rem(24);
			}
			span {
				display: block;
			}
			@media #{$md-1} {
				width: 58.33%;
				padding: rem(80);
				h2 {
					font-size: rem(50);
				}
			}
			@media #{$xxl} {
				padding: rem(112) rem(118) rem(112) rem(120);
				h2 {
					font-size: rem(64);
				}
			}
			@media #{$vl} {
				h2 {
					font-size: rem(80);
					margin-bottom: rem(30);
				}
			}
		}

		.listWrapper {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			border-top: rem(1) solid $deepBlue;
			border-bottom: rem(1) solid $deepBlue;
			@media #{$md-1} {
				flex-direction: row;
			}
			@media #{$vl} {
				border-right: rem(1) solid $deepBlue;
			}

			.list {
				flex: 1;
				color: $deepBlue;
				&::before {
					content: '';
					position: absolute;
					width: rem(1);
					height: 100%;
					top: 0;

					background-color: $deepBlue;
					color: $deepBlue;
				}
				&:hover {
					background-color: $deepBlue;
					color: $white;
				}
				&:hover {
					h5 {
						color: $white;
					}
					p {
						color: $white;
					}
				}
			}
		}

		.listContent {
			// flex: 1;
			padding: rem(48) rem(20);
			border-bottom: rem(1) solid $deepBlue;
			h3 {
				font-size: rem(28);
				font-weight: 300;
				line-height: 100%;
				margin-bottom: rem(24);
			}
			@media #{$md-1} {
				border-bottom: none;
			}
			@media #{$lgn} {
				padding: rem(38) rem(39.5);
			}

			@media #{$vl} {
				h3 {
					font-size: rem(35);
				}
				p {
					font-size: rem(20);
					width: auto;
				}
			}
		}
	}
}
