@use '../../styles/shared/include' as *;

.footerContainer {
	display: flex;
	flex-direction: column;
  flex-grow: 1;
	background-color: $cream;
	color: var(--Light-UI-Text-Secondary, #4F5E78);
	font-family: "DM Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	padding: 16px;
	align-items: stretch;
	gap: 30px;
	align-self: stretch;
	overflow: hidden;
	

	@media #{$md} {
		display: flex;
		flex-direction: row;
	}
	
	@media #{$xl} {
		padding: 20px 40px;
	}
	
	.copyright {
		color: var(--Light-UI-Text-Secondary, #4F5E78);
		font-family: "DM Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		overflow: hidden;
    text-align: left;
		
		@media #{$xl} {
			white-space: nowrap;
		}
	}

	.links {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		gap: 8px;
		flex: 1 0 0;
		overflow: hidden;
		flex-wrap: wrap;

		// div > a {
		// 	white-space: nowrap;
		// }
	}

	.address {
		color: var(--Light-UI-Text-Secondary, #4F5E78);
		font-family: "DM Sans";
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		white-space: nowrap;
		overflow: hidden;

		@media #{$xl} {
			white-space: nowrap;
		}
	}
}
