.accordionItem {
  padding: 1.5rem 0 .5rem;
  border-top: solid 1px;
  position: relative;
 
  &:last-of-type {
    border-bottom: solid 1px;
  }

  .checkbox {
    position: absolute;
    opacity: 0;
  }
  
  .label {
    display: flex;
    gap: .5rem;
  
    .icon {
      min-width: 25px;
      width: 25px;
      height: 25px;
      transform: rotate(-90deg);
      transition: transform 0.15s ease-in-out;
    }
  }
  
  .checkbox:checked + .label {
    .icon {
      transform: rotate(0deg);
    }
  }
  
  .checkbox:checked + .label + .content {
    grid-template-rows: 1fr;
  }

  .checkbox:focus + .label {
    outline: 1px dashed;
    outline-offset: 3px;
    outline-color: var(--bw-foreground);
  }
  
  .content {
    display: grid;
    grid-template-rows: 0fr;
    margin: .5rem;
    transition: grid-template-rows 200ms ease-in-out;

    & > div {
      overflow-y: hidden;
    }
  }
}
