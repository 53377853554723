@use '../../styles/shared/include' as *;

.footerContainer {
	background-color: $deepBlue;
	color: $white;
	@media #{$xxl} {
		flex-direction: column;
		align-items: flex-start;
	}
	.footerLogo {
		height: rem(100);
		width: rem(109);
		padding-top: rem(48);
		img {
			width: 100%;
			height: auto;
		}
		@media #{$xl} {
			padding-top: 0;
			margin-top: rem(64);
			position: absolute;
			img {
				height: rem(189);
				width: rem(206);
			}
		}
	}
}
.footerBottom {
	padding: rem(0) rem(0) rem(48) rem(20);
	@media #{$md} {
		padding: rem(0) rem(20) rem(48) rem(50);
	}
	.footerLinks {
		display: flex;
		font-weight: 400;
		line-height: 140%;
		flex-wrap: wrap;

		li:not(:last-child)::after {
			content: '|';
			margin: 0 8px;
			color: $white !important;
		}
		li {
			a {
				@include transition();
				color: $white;
			}
			&:hover {
				a {
					color: $brightYellow;
				}
			}
		}
	}

	.footerCopyright.captchaNotice {
		font-size: rem(12.8);
		opacity: 0.75;
	}

	.footerCopyright {
		font-size: rem(16);
		font-weight: 400;
		a {
			text-decoration: underline;
			&:hover {
				color: $brightYellow;
			}
		}
	}
	@media #{$xl} {
		.linksCopyrightWrapper {
			display: flex;
			margin-bottom: rem(8);
		}
		.footerCopyright {
			text-align: left;
			line-height: 140%;
		}
		.footerLinks {
			margin-left: rem(28);
			display: flex;
			a {
				text-decoration: none;
			}
		}
	}
	@media #{$xl} {
		justify-content: flex-start;
		padding: rem(0) rem(0) rem(32) rem(350);
	}
	@media #{$xxl} {
		padding: rem(0) rem(0) rem(32) rem(491);
	}
}
.footerTextSection {
	.footerSections {
		display: flex;
		align-items: flex-start;
		align-content: flex-start;
		flex-wrap: wrap;
		padding: rem(96) rem(0) rem(24) rem(20);
		line-height: rem(29);
		@media #{$md} {
			padding: rem(96) rem(20) rem(48) rem(50);
			flex-wrap: nowrap;
		}
		@media #{$xl} {
			padding: rem(64) rem(32) rem(112) rem(350);
		}
		@media #{$xxl} {
			padding: rem(64) rem(32) rem(112) rem(491);
			flex-wrap: nowrap;
		}
		.footerSection {
			width: 48%;
			&:nth-child(odd) {
				margin-right: rem(11);
			}
			@media #{$md} {
				width: 25%;
				&:nth-child(odd) {
					margin-right: rem(0);
				}
			}
			.listHeader {
				color: $brightYellow;
				margin-bottom: rem(6);
				font-weight: 800;
				font-family: $secondary;
				font-size: rem(12);
				font-style: normal;
				line-height: rem(20);
				letter-spacing: rem(1.8);
				text-transform: uppercase;
				display: block;
			}
			ul {
				list-style: none;
				padding-bottom: rem(24);
				@media #{$xl} {
					padding-bottom: 0;
				}
				a {
					font-weight: 400;
					line-height: 140%;
					@include transition();
					&:hover {
						color: $brightYellow;
					}
				}
			}
			&--franchiseSection {
				&:nth-child(odd) {
					margin-right: rem(11);
				}
				@media #{$md} {
					&:nth-child(odd) {
						margin-right: rem(0);
					}
					width: 38%;
				}
				h3 {
					color: $brightYellow;
					margin-bottom: rem(6);
					font-weight: 800;
					font-family: $secondary;
					font-size: rem(12);
					font-style: normal;
					line-height: rem(20);
					letter-spacing: rem(1.8);
					text-transform: uppercase;
				}
				ul {
					list-style: none;
					padding-bottom: rem(24);
					@media #{$xl} {
						padding-bottom: 0;
					}
					a {
						font-weight: 400;
						line-height: 140%;
						@include transition();
						&:hover {
							color: $brightYellow;
						}
					}
				}
			}
		}
		.AddressIconContainer {
			.addressSection {
				color: $white;
				.addressHeading {
					font-family: $primary;
					text-transform: none;
					font-size: rem(16);
					font-weight: 400;
					text-wrap: nowrap;
					line-height: 140%;
					letter-spacing: 0;
					display: block;
				}
				p {
					line-height: 140%;
					font-weight: 400;
					font-size: rem(16);
					padding-bottom: rem(24);
					white-space: nowrap;
				}
			}
			.socialIcons {
				a {
					margin-right: rem(16);
					svg {
						path {
							fill: $white !important;
						}
						&:hover {
							path {
								fill: $brightYellow !important;
							}
						}
					}
				}
			}
		}
	}
}
.visuallyHidden {
	display: none;
}
