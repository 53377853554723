@use '../../../../styles/shared/include' as *;
.heroBanner {
	display: flex;
	flex-direction: column;
	@media #{$md} {
		flex-direction: row;
	}
	min-height: calc(100vh - (221px + var(--announcement-banner-height)));

	.textContent {
		width: 100%;
		padding: rem(48) rem(20);
		text-align: left;
		align-self: center;
		justify-content: center;
		h1 {
			font-size: rem(40);
			font-weight: 300;
			line-height: 100%;
			margin-bottom: rem(17);

			@media #{$nm} {
				font-size: rem(40);
			}
			@media #{$md} {
				min-width: unset;

				font-size: rem(60);
			}

			@media #{$xxl} {
				font-size: rem(64);
			}
			@media #{$vl} {
				font-size: rem(90);
				padding-bottom: rem(30);
			}
		}

		p {
			margin-bottom: rem(22);
			@media #{$nm} {
				max-width: 90%;
			}
			@media #{$md} {
				max-width: 100%;
			}
		}
		ul {
			li {
				p {
					padding-top: 0 !important;
					margin-bottom: 0;
				}
			}
		}

		@media #{$md} {
			width: 58.33%;
		}

		@media #{$lg} {
			padding: rem(112) rem(120);
		}

		@media #{$vl} {
			p {
				font-size: rem(25);
			}
		}

		&--design-2 {
			@media #{$md} {
				h1 {
					font-size: rem(48);
				}
			}
		}
	}

	.imageContent {
		width: 100%;
		height: auto;
		img {
			display: block;

			object-position: center;
			width: 100%;
			height: 50%;
		}
		@media #{$nm} {
			img {
				height: 90%;
			}
		}
		@media #{$md} {
			img {
				object-fit: cover;
				height: 100%;
			}
			h1 {
				min-width: rem(600);
			}

			width: 42%;
		}
	}
	&--imagePosition {
		@media #{$md} {
			flex-direction: row-reverse;
		}
	}
	&--equalWidth {
		.imageContent {
			@media #{$md} {
				width: 50%;
			}
		}

		.textContent {
			@media #{$md} {
				width: 50%;
			}
			p {
				margin-bottom: rem(48);
			}
		}
	}

	&--DeepBlue {
		background: $deepBlue;
		color: $white;
		.textContent {
			h5 {
				color: $brightYellow;
			}
		}
	}
}
