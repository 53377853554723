@use '../../styles/shared/include' as *;

.container {
	min-height: calc(100vh - 72px);
	@media #{$xl} {
		min-height: calc(100vh - (72px + 532px));
	}
	container-type: inline-size;
	padding-bottom: 1rem;

	:global(a.cc-back-to-jobs-button) img {
		display: inline-block;
	}

	button,
	:global(.link-button) {
		font-weight: 500;
		color: $deepBlue;
		border-radius: 6.25rem;
		background-color: $brightYellow;
	}

	@media #{$md-m} {
		:global(.cc-careers-container.search-left.include-insights) {
			display: block;

			> div,
			> section {
				padding-inline: 15px; // matches built in styling from clearcompany
			}
		}
	}

	@media #{$md} {
		:global(.clearcompany-include) {
			padding-right: 1rem;

			:global(.cc-portal-share-container) {
				padding-top: 0;
			}
		}
	}

	@media #{$lgn} {
		min-height: calc(100vh - rem(80));
		:global(.clearcompany-include) {
			:global(.cc-careers-container.search-left.include-insights) {
				gap: 20px;
			}

			padding-right: 2rem;
			padding-block: 0.75rem;

			:global(.cc-filters-container) {
				padding: 2rem;
			}

			:global() {
				:global(.cc-job-description-container) {
					padding-inline: 2rem 0;
				}
			}
		}
	}

	:global(.cc-job-portal-link) {
		font-weight: bold;
		text-decoration: underline;
		padding-top: 3px;
	}

	:global(.cc-filters-container) {
		background-color: $deepBlue;

		:global(.cc-reset-search-button) {
			color: white;
		}
	}

	:global(.cc-share-container) {
		a,
		button {
			background-color: $deepBlue;
			border-radius: 5px;

			img {
				filter: invert(1);
			}
		}

		:global(#cc-twitter-logo-id) {
			display: none;
		}

		:global(#cc-copy-url-logo-id) {
			// transform: scale(0.985, 0.9);
			display: none;
		}
	}

	:global(.cc-job-description-title) {
		line-height: 1.1;
		padding-bottom: 0.5rem;
		margin-bottom: 0.5rem;
	}

	:global(.cc-job-description-container) {
		padding-inline: 15px;
		p {
			margin-block: 1rem;
		}

		li {
			margin-block: 0.5rem;
		}
	}
}
