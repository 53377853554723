@use '../../styles/shared/include' as *;
.CompaniesBanner{
  background-color: $deepBlue;
  :global{
    .container--max{
      position: relative;
      padding: 0;
    }
    .banner__content{
      padding: rem(50) 0 rem(24);
      width: rem(840);
      max-width: 100%;
      @media #{$md}{
        max-width: 80%;
      }
      @media #{$lg}{
        padding: rem(112) 0 rem(44);
      }
      h1{
        font-size: rem(36);
        color: $white;
        line-height: 100%;
        font-weight: 300;
        margin-bottom: rem(16);
        @media #{$lg}{
          font-size: rem(64);
          margin-bottom: rem(24);
        }
      }
      p{
        color: $white;
        font-size: rem(16);
        line-height: 140%;
        font-weight: 500;
        margin-bottom: 0;
        @media #{$lg}{
          font-size: rem(18);
        }
        &+p{
          margin-top: rem(16);
          @media #{$lg}{
            margin-top: rem(25.2);
          }
        }
        a{
          text-decoration: underline;
          @include transition();
          &:hover{
            color: $brightYellow;
          }
        }
      }
    }
    
    .banner__logo-layer{
      position: absolute;
      right: 0;
      bottom: rem(14);
      overflow: hidden;
      img{
        display: none;
        @media #{$md}{
          display: block;
          max-width: rem(100);
        }
        @media #{$lg}{
          max-width: rem(141);
        }
      }
    }
  } //Global end
}