@use 'shared/include' as *;
.mortgageVerificationThankYou, .mortgageVerificationThankYou.isSubmitted {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  background: var(--Dark-UI-Surface-Brand, #F0FF00);
  color: var(--Deep-Blue, #003049);
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;

  .headerSubmitted {
    background-color: transparent !important;
    border-bottom: none;
    div, header {
      background-color: transparent !important;
      border-bottom: none;
    }

    img, svg {
      width: 7.625rem;
      filter: unset;
      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      animation: fadeIn 500ms ease-in;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 0px 24px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    
    @keyframes fadeInZoom {
      0% {
        transform: scale(0.7);
        opacity: 0;
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    }
    animation: fadeInZoom 500ms ease-in;
  }
  

  h1 {
    color: var(--Deep-Blue, #003049);
    text-align: center;
    font-family: "DM Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
  }
  
  @media #{$sml} {
    font-size: 24px;
    h1 {
      font-size: 60px;
    }
  }
  
  @media screen and (min-width: 874px) {
    font-size: 32px;
    h1 {
      font-size: 80px;
    }
  }
}