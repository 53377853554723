@use '../../../../styles/shared/include' as *;

.insuranceHalfTextHalfMapSection {
	background-color: $deepBlue;

	.insuranceHalfTextHalfMap {
		display: flex;
		flex-direction: column;
		@media #{$lgn} {
			flex-direction: row;
		}
		.textSection {
			padding: rem(40);
			font-family: $secondary;
			color: $white;
			@media #{$lgn} {
				padding: rem(50) rem(30);
			}
			h3 {
				font-size: rem(32);
				font-weight: 300;
				line-height: rem(44);
				b {
					font-style: normal;
					color: $brightYellow;
				}
			}
			h4 {
				font-size: rem(18);
				font-weight: 500;
				line-height: 140%;
				padding: rem(24) 0;
			}
			p {
				font-size: rem(12);
				font-weight: 800;
				line-height: rem(20);
				letter-spacing: rem(1.8);
				text-transform: uppercase;
				color: $brightYellow;
				padding-bottom: rem(8);
			}
			h5 {
				font-size: rem(20);
				font-weight: 500;
				line-height: 140%;
			}
			.address {
				padding-bottom: rem(10);
			}
			@media #{$lgn} {
				width: 50%;
				padding: rem(112) rem(119) rem(112) rem(120);
				h3 {
					font-size: rem(64);
					line-height: 100%;
				}
			}
		}
		.mapSection {
			height: auto;
			width: 100%;
			@media #{$sm} {
				flex-direction: column;
			}
			@media #{$lgn} {
				width: 50%;
			}
			.mapContainer {
				position: relative;
				width: 100%;
				height: rem(659);
				@media #{$lgn} {
					height: 100%;
				}
			}
			.mapWrapper {
				height: 100%;
			}
		}
	}
}
