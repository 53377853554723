@use '../../../styles/shared/include' as *;

.AgencyReferralSection {
	.ReferralForm {
		color: $deepBlue;
		@media #{$lg} {
			display: flex;
		}
		article {
			padding: rem(50) rem(20) 0 rem(20);
			@media #{$lg} {
				width: 58.33%;
			}

			h3 {
				font-size: rem(54);
				font-weight: 300;
				line-height: 100%;
				padding-bottom: rem(40);
				max-width: rem(600);

				@media #{$xl} {
					font-size: rem(64);
				}
			}
      h4 {
				font-size: rem(44);
				font-weight: 300;
				line-height: 100%;
				padding-bottom: rem(40);
				max-width: rem(600);
        text-align: left;

				@media #{$xl} {
					font-size: rem(54);
				}
			}
			fieldset {
				display: flex;
				flex-direction: column;
				label {
					font-size: rem(16);
					color: $black;
					font-weight: 500;
					line-height: rem(20);
					margin-bottom: rem(4);
				}
				input {
					font-family: $primary;
					border: rem(1) solid $periwinkleGrey;
					padding: rem(12) rem(24);
					align-items: center;
					gap: rem(8);
				}

				span {
					display: block;
					height: rem(24);
					margin-bottom: rem(8);
					color: red;
				}
				textarea {
					padding: rem(12) rem(24);
					border: rem(1) solid $periwinkleGrey;
					height: rem(147);
					font-family: $primary;
				}
				a {
					margin-bottom: rem(30);
					width: rem(200);
					@media #{$lg} {
						margin-bottom: 0;
					}
				}
        .error {
          margin-top: rem(24);
          color: red;
        }
        .fieldContainer {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .splitField {
          width: 100%;
          input {
            width: 100%;
          }
        }
			}
			@media #{$lg} {
				padding: rem(60);
			}
			@media #{$xl} {
				fieldset {
					input {
						width: 100%;
						height: rem(44);
					}
					textarea {
						width: 100%;
						height: rem(147);
					}
				}
				padding: rem(112) rem(120);
				width: 58.333333333333%;
			}
      @media #{$md} {
				fieldset {
          .fieldContainer {
            flex-direction: row;
          }
					.splitField {
            width: 45%;
            input {
              width: 100%;
            }
          }
				}
			}
			.success {
				display: flex;
				flex-direction: column;
				justify-content: center;
				height: 100%;
				h2 {
					font-size: rem(64);
					font-style: normal;
					font-weight: 300;
					line-height: 100%;
					margin-bottom: rem(24);
				}
			}
		}
		.TextImageSection {
			background-color: $cream;
			@media #{$md-1} {
				display: flex;
				flex-direction: column;
			}

			.TextSection {
				padding: rem(50) rem(20);
				@media #{$lg} {
					display: flex;
					height: 100%;
					flex-direction: column;
					justify-content: center;
				}
				.KeyAndValue {
					&:not(:last-child) {
						margin-bottom: rem(32);
					}
					p {
						font-family: $secondary;
						font-size: rem(12);
						font-weight: 800;
						line-height: rem(20);
						letter-spacing: rem(1.8);
						text-transform: uppercase;
						padding-bottom: rem(8);
					}
					h6 {
						font-size: rem(20);
						font-weight: 500;
						line-height: rem(28);
						@media #{$xl} {
							min-width: rem(380);
						}
					}
					a {
						font-size: rem(20);
						font-weight: 500;
						line-height: rem(28);
					}
				}
			}
			figure {
				@media #{$md-1} {
					flex: 1;
				}
				img {
					width: 100%;
					object-fit: cover;
					height: auto;
					@media screen and (max-width: 991px) {
					}
				}
			}
			@media #{$xl} {
				.TextSection {
					padding: rem(112) rem(117) rem(112) rem(120);
				}
				width: 41.6666666667%;
				figure {
					width: 100%;
				}
				.TextValue {
					text-wrap: nowrap;
				}
			}
		}
	}
  .imageSection {
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}

		@media #{$lgn} {
			height: auto;
			width: calc((600 / 1440) * 100%);
		}
	}
  :global {
    .is__pageloader {
      position: static;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      figure {
        width: rem(60);
        @media #{$lg} {
          width: rem(100);
        }
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

