.remote-svg {
	img,
	svg {
		width: var(--bw-svg-width, 100%);
		height: var(--bw-svg-height, auto);
	}

	svg,
	path {
		fill: currentColor;
	}
}
