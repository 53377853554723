@use '../../styles/shared/include' as *;
.agentMapComponent {
	display: none;
	height: 100vh;
	width: 100%;

	.mapContainer {
		position: relative;
		width: 100%;
		height: 100%;
	}

	.markerAgencyName {
		font-size: rem(16);
		font-style: normal;
		font-weight: 500;
		line-height: 140%;
		color: $white;
	}
	.markerAgencyAddress {
		font-size: rem(14);
		font-style: normal;
		font-weight: 300;
		line-height: 140%;
		color: $white;
	}
	.markerLink {
		font-size: rem(14);
		font-weight: 500;
		color: $white;
		&:hover {
			color: $brightYellow;
		}
	}

	.searchBox {
		background-color: $deepBlue;

		width: 100%;
		height: fit-content;
		color: $white;

		h5 {
			font-size: rem(16);
			font-weight: 500;
			line-height: 125%;
		}
		.searchDropdownContainer {
			position: relative;
			display: inline-block;
			width: 100%;
		}
		label {
			color: $white;
			font-family: $primary;
			font-size: rem(16);
			font-weight: 500;
			line-height: 125%;
		}

		.searchContainer {
			display: flex;
			justify-content: flex-start;
			width: 100%;
			border-radius: rem(3);
			border: rem(1) solid $white;
			margin: rem(4) 0 rem(15);
			input {
				border-radius: 0;
				padding: rem(12) rem(16);
				width: calc(100% - rem(44));

				@media #{$lgn} {
					width: calc(100% - rem(145));
				}
			}
			button {
				cursor: pointer;
				color: $deepBlue;
				font-weight: 500;
				line-height: rem(20);
				font-size: rem(12);
				font-family: $primary;
				display: flex;
				width: fit-content;
				gap: rem(8);
				align-items: center;
				padding: rem(0) rem(12);
				background-color: $cream;
				border-radius: 0 rem(2) rem(2) 0;
				position: relative;
				&::before {
					position: absolute;
					content: '';
					height: 100%;
					width: rem(1);
					color: $periwinkleGrey;
					background-color: $periwinkleGrey;
					top: 0;
					left: 0;
				}
				@media #{$sm} {
					padding: rem(0) rem(12);
				}
				@media #{$lgn} {
					font-size: rem(14);
					padding: rem(12) rem(24) rem(12) rem(12);
					width: rem(145);
				}

				img {
					width: rem(20);
					height: rem(20);
					@media #{$lgn} {
						width: rem(20);
						height: rem(20);
					}
				}
				.loader {
					width: 100%;
					height: rem(20);
				}
			}
			.searchLocationBtn {
				display: none;
				@media #{$lgn} {
					display: block;
				}
			}
		}

		.buttonContainer {
			a {
				font-size: rem(16);
				font-style: normal;
				font-weight: 500;
				line-height: rem(20);
				color: $deepBlue;
				background-color: $brightYellow;
				padding: rem(12) rem(24);
				justify-content: center;
				align-items: center;
				gap: 8px;
				border-radius: 100px;

				&:hover {
					color: $brightYellow;
					background-color: $deepBlue;
				}
			}
			span {
				color: $white;
			}
			margin-bottom: rem(2);
		}

		.autocompleteItems {
			position: absolute;
			border: 1px solid $periwinkleGrey;
			z-index: 99;
			top: 100%;
			left: 0;
			right: 0;
			background-color: $white;
			color: $deepBlue;
			margin-bottom: rem(3);
			max-height: rem(320);
			overflow: auto;
		}

		.autocompleteItems {
			cursor: pointer;
			color: $deepBlue;
			border-bottom: 1px solid #d4d4d4;
		}

		.autocompleteItem {
			padding: rem(10) rem(16);
			&--selected {
				background: $deepBlue;
				color: $white;
			}
			&:hover,
			&:focus {
				background: $deepBlue;
				color: $white;
			}
		}
	}

	.contentWrapper {
		background-color: $deepBlue;
		.heading {
			font-size: rem(26);
			width: 100%;
			font-weight: 300;
			padding-bottom: rem(32);
			line-height: 100%;
			color: $white;

			@media #{$md} {
				span {
					font-size: rem(32);
					font-weight: 300;
				}
				font-size: rem(32);
				width: 100%;
			}
		}
		padding: rem(24);

		@media #{$md} {
			width: 100%;
			padding: rem(40) rem(40) rem(36.04);
		}
	}

	.resultBox {
		font-size: rem(16);
		display: flex;
		cursor: pointer;
		flex-direction: column;
		justify-content: space-between;
		gap: rem(25);
		background-color: $white;
		padding: rem(24);
		width: 100%;
		height: max-content;
		color: $deepBlue;
		border-right: rem(1) solid $deepBlue;
		border-bottom: rem(1) solid $deepBlue;
		@media #{$lgn} {
			flex-direction: row;
		}
		@media #{$xl} {
			padding: rem(30);
			gap: rem(24);
		}
		@media #{$xxl} {
			padding: rem(40) rem(40) rem(40) rem(40);
		}
		.textContainer {
			min-width: rem(284);
			h2 {
				font-size: rem(24);
				font-style: normal;
				font-weight: 300;
				padding-bottom: rem(7);
				line-height: 100%;
			}
			.distance {
				font-family: $secondary;
				font-size: rem(14);
				font-style: normal;
				font-weight: 800;
				line-height: 140%;
				letter-spacing: rem(2.1);
				text-transform: uppercase;
				padding-bottom: rem(8);
			}
			.places {
				font-size: rem(16);
				font-style: normal;
				font-weight: 500;
				line-height: 140%;
				padding-bottom: rem(10);

				padding-bottom: rem(10);
				span {
					color: $periwinkleGrey;
				}
				@media #{$lgn} {
					padding-bottom: rem(25);
				}
			}
			address {
				font-size: rem(16);
				font-style: normal;
				font-weight: 500;
				line-height: 140%;
				margin-bottom: rem(16);
				// max-width: rem(240);
			}
			.contact {
				cursor: text;
				font-size: rem(16);
				font-style: normal;
				font-weight: 500;
				line-height: 140%;
				.phone {
					cursor: pointer;
				}
				&:hover {
					color: $hoverBlue;
				}
			}
		}
		&--active {
			background-color: $cream;
		}
	}
	.actionSection {
		display: block;

		text-align: center;

		width: fit-content;

		.buttonContainer {
			width: max-content;
			padding-bottom: rem(16);
			.button {
				width: fit-content;
				padding: rem(8) rem(11);
				min-width: fit-content;
				font-size: rem(20);
				font-weight: 500;
				line-height: rem(20);
				border-radius: rem(100);
				border-radius: 100px;
				border: 1px solid $deepBlue;
				cursor: pointer;
				text-align: center;
				&:hover {
					background-color: $deepBlue;
					color: $white;
				}
			}
			@media #{$md} {
				width: 100%;
			}
		}
		a {
			position: relative;
			height: fit-content;
			line-height: 140%;
			&:hover {
				color: $hoverBlue;
			}
		}
		.email {
			&::after {
				content: '';
				position: absolute;
				width: 100%;
				height: rem(1);
				background-color: $deepBlue;
				bottom: rem(3);
				left: 0;
			}
			&:hover::after {
				background-color: $hoverBlue;
			}
		}

		@media #{$md} {
			display: block;
			min-width: rem(91);
		}
	}
	.sectionContainer {
		position: relative;
		width: 100%;
		height: 100%;
		border-top: rem(1) solid $white;
		@media #{$md} {
			position: absolute;
			width: 47%;
			overscroll-behavior: auto !important;
			overflow-y: auto;
		}
		@media #{$xl} {
			width: 33.33%;
		}
	}

	.buttonContainer {
		width: 100%;
		display: flex;
		justify-content: space-between;
		a {
			font-size: rem(16) !important;
			width: fit-content;
		}

		span {
			color: $periwinkleGrey;
			display: flex;
			font-size: rem(18);
			gap: rem(8);
			font-style: normal;
			font-weight: 500;
			line-height: 140%;
			min-width: rem(122);
			align-items: flex-end;
		}
		button {
			font-size: rem(16) !important;
		}
	}
	@media #{$md} {
		display: block;
	}

	&--NoMap {
		display: block;
		width: 100%;
		height: 100vh;

		@media #{$lgn} {
			display: none;
		}
	}

	/* Change the width of the scrollbar */
	::-webkit-scrollbar {
		width: rem(4); /* Adjust the width as needed */
	}

	/* Change the background color of the scrollbar track */
	::-webkit-scrollbar-track {
		background: #eff1f2; /* Change to your desired background color */
	}

	/* Change the color of the scrollbar thumb (the draggable part) */
	::-webkit-scrollbar-thumb {
		background: #656a6e; /* Change to your desired thumb color */
	}
}
