@use '../../styles/shared/include' as *;

.mapContainer {
	position: relative;
	width: 100%;
	height: 100%;
}

.mapWrapper {
	height: 100%;
}

.markerAgencyName {
	font-size: rem(16);
	font-style: normal;
	font-weight: 500;
	line-height: 140%;
	color: $white;
}
.markerAgencyAddress {
	font-size: rem(14);
	font-style: normal;
	font-weight: 300;
	line-height: 140%;
	color: $white;
}
.markerLink {
	font-size: rem(14);
	font-weight: 500;
	color: $white;
	&:hover {
		color: $brightYellow;
	}
}
.markerLabelText {
	visibility: hidden;
}
